window.addEventListener('load', () => {
    const bandeau = document.querySelector('.marquee-wrapper .inner');
    const pElement = bandeau.querySelector('.texte');
    const pWidth = pElement.offsetWidth;
    const toRepeat = pElement.outerHTML; // Obtient le HTML complet du <p>
    const separateur = '<p class="separator"></p>';
    let contenu = '';

    if(pWidth > 0) {

        // Calcul de la largeur nécessaire
        let largeurContenu = -pWidth;
        while (largeurContenu < window.innerWidth) {
            contenu += (contenu ? separateur : '') + toRepeat;
            bandeau.innerHTML = contenu;
            largeurContenu += pWidth;
        }

        // Définir l'animation avec la largeur de pElement
        const separatorWidth = bandeau.querySelector('.separator').offsetWidth;
        const animationWidth = pWidth + separatorWidth;
        const animationName = 'defilement-' + animationWidth;

        
        
        // Définir une vitesse constante pour l'animation (par exemple, 100 pixels par seconde)
        const speed = 80; // pixels par seconde
        
        // Calculer la durée basée sur la largeur de l'animation et une vitesse constante
        const animationDuration = animationWidth / speed; // Durée en secondes
        
        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = `
        @keyframes ${animationName} {
            from { transform: translateZ(0) translateX(0); }
            to { transform: translateZ(0) translateX(-${animationWidth}px); }
        }
        .marquee-wrapper .inner {
            animation: ${animationName} ${animationDuration}s linear infinite;
        }
        `;

        document.head.appendChild(styleSheet);
    } else {
        console.error('La largeur du texte est nulle');
    }
});