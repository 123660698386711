// Add a class on .navbar as soon as we leave the body top, in vanilla JS
//
// Add a class on .navbar as soon as we leave the body top, in vanilla JS
window.addEventListener('scroll', function() {
    var navbar = document.querySelector('.navbar');
    if (window.scrollY > 0) {
        navbar.classList.add('scrolled');
    } else {
        navbar.classList.remove('scrolled');
    }
});
