
// Gestion de la fermeture de la nav
$("#navbar a").click(function() {
  $('.collapse').collapse('hide');
});


// Smooth scrolling vers les anchor
$(document).ready(function() {
	$('.js-scrollTo').on('click', function() { // Au clic sur un élément
		var page = $(this).attr('href'); // Page cible
		var speed = 500; // Durée de l'animation (en ms)
		$('html, body').animate( { scrollTop: $(page).offset().top }, speed ); // Go
		return false;
	});
});


// Gestion de la navigation dans les cartes
$(".carte_nav_bouton.creperie").click(function() {
	id_carte = '#'+$(this).attr('id');
	id_table = '#carte_'+id_carte.substr(11); // on enlève '#carte_nav_' du début de l'ID
	$(".carte_nav_bouton.creperie").removeClass('active'); // on enlève la classe "active" partout
	$(id_carte).addClass('active'); // on remet la class "active" sur le bouton qui a été clique
	$(".carte_table").removeClass('display'); // on enlève la classe "display" de tous les tableaux
	$(id_table).addClass('display'); // on affiche le tableau qui a été demandé
});

$(".carte_nav_bouton.salon").click(function() {
	id_carte = '#'+$(this).attr('id');
	id_table = '#carte_'+id_carte.substr(11); // on enlève '#carte_nav_' du début de l'ID
	$(".carte_nav_bouton.salon").removeClass('active'); // on enlève la classe "active" partout
	$(id_carte).addClass('active'); // on remet la class "active" sur le bouton qui a été clique
	$(".carte_table_salon").removeClass('display'); // on enlève la classe "display" de tous les tableaux
	$(id_table).addClass('display'); // on affiche le tableau qui a été demandé
});
// FIN Gestion de la navigation dans les cartes


// Gestion de la carte google maps 
if (document.getElementById('carte_googlemaps')) { 

    function initialisation(){
        var optionsCarte = {
            zoom: 12, // zoom out to show both points
            center: new google.maps.LatLng(46.332700, 6.057909), // can be updated to fit both markers later
            scrollwheel: false,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false
        };

        var maCarte = new google.maps.Map(document.getElementById("carte_googlemaps"), optionsCarte);

        // Marker 1 - GEX
        var positionGex = new google.maps.LatLng(46.332700, 6.057909);
        var markerGex = new google.maps.Marker({
            position: positionGex,
            map: maCarte,
            title: "Crêperie Gex"
        });

        // Marker 2 - DIVONNE
        var positionDivonne = new google.maps.LatLng(46.35685777453929, 6.1449468607586475); // example coords for Divonne-les-Bains
        var markerDivonne = new google.maps.Marker({
            position: positionDivonne,
            map: maCarte,
            title: "Crêperie Divonne"
        });

        // Auto-adjust map bounds to show both markers
        var bounds = new google.maps.LatLngBounds();
        bounds.extend(positionGex);
        bounds.extend(positionDivonne);

        // Expand bounds artificially
        var sw = bounds.getSouthWest();
        var ne = bounds.getNorthEast();

        // Increase lat/lng "distance" to zoom out a bit more
        var paddingLat = 0.02; // about 2 km depending on zoom level
        var paddingLng = 0.02; 

        bounds.extend(new google.maps.LatLng(sw.lat() - paddingLat, sw.lng() - paddingLng));
        bounds.extend(new google.maps.LatLng(ne.lat() + paddingLat, ne.lng() + paddingLng));

        maCarte.fitBounds(bounds);
        
    }

    google.maps.event.addDomListener(window, 'load', initialisation);

};
// Fin affichage google maps


// Gestion du bouton ReCaptcha
/*function onSubmit(token) {
    document.getElementById("real_submit_btn").click();
}
*/

// Popover du footer (mentions légales)
$("[data-toggle=popover]").popover({
  html:true,
  content: function(){ return $('#legal-mentions').html()} 
});
// Fin Popover


// Gestion fermeture des modales
$(".croix_fermeture").click(function() {

  $(".modal").modal('hide');

});


// Gestion ouverture modale
$(document).ready(function() {
    $('.open-modal-image').click(function() {
        var index = $(this).data('index');
        // Activate the correct slide inside the modal
        $('#carousel_1 .carousel-inner .item').removeClass('active');
        $('#carousel_1 .carousel-inner .item').eq(index).addClass('active');
    });
});
