// FORMULAIRE AJAX : Contact

/*$(document).ready(function() {
    // Lorsque je soumets le formulaire
    //$(document).off('submit');
    $('#form_contact').on('submit', function(e) {
        alert('submitted !');
        e.preventDefault(); // J'empêche le comportement par défaut du navigateur, c-à-d de soumettre le formulaire
        //e.stopImmediatePropagation();
 
        var $this = $(this); // L'objet jQuery du formulaire
 
        // Je récupère les valeurs
        var nom = $('#nom').val();
        var mail = $('#email').val();
        var message = $('#message').val();

        var data = $(this).serializeArray(); // convert form to array

        // Je vérifie une première fois pour ne pas lancer la requête HTTP
        // si je sais que mon PHP renverra une erreur
        if(nom === '' || mail === '' || message === '') {
            alert("Tous les champs doivent êtres remplis");
        } else {
            // Envoi de la requête HTTP en mode asynchrone
            $.ajax({
                url: $this.attr('action'), // Le nom du fichier indiqué dans le formulaire
                type: $this.attr('method'), // La méthode indiquée dans le formulaire (get ou post)
                captcha: grecaptcha.getResponse(),
                data: $.param(data), // Je sérialise les données (j'envoie toutes les valeurs présentes dans le formulaire)
                success: function(resultat) { // Je récupère la réponse du fichier PHP
                    alert(resultat);
                    if(resultat == 'ok') { 
                        document.getElementById('confirmation_envoi_mail_ok').style.display = 'block';
                    } else {
                        document.getElementById('confirmation_envoi_mail_fail').style.display = 'block';
                    }
                }
            });
        }
    });
});*/


function onSubmit(token) {

    var $this = $('#form_contact'); // L'objet jQuery du formulaire

    // Je récupère les valeurs
    var nom = $('#nom').val();
    var mail = $('#email').val();
    var message = $('#message').val();

    var data = $this.serializeArray(); // convert form to array

    // Je vérifie une première fois pour ne pas lancer la requête HTTP
    // si je sais que mon PHP renverra une erreur
    if(nom === '' || mail === '' || message === '') {
        alert("Tous les champs doivent êtres remplis");
    } else {
        // Envoi de la requête HTTP en mode asynchrone
        $.ajax({
            url: $this.attr('action'), // Le nom du fichier indiqué dans le formulaire
            type: $this.attr('method'), // La méthode indiquée dans le formulaire (get ou post)
            data: $.param(data), // Je sérialise les données (j'envoie toutes les valeurs présentes dans le formulaire)
            success: function(resultat) { // Je récupère la réponse du fichier PHP
                //alert(resultat);
                if(resultat == 'ok') { 
                    document.getElementById('confirmation_envoi_mail_ok').style.display = 'block';
                } else {
                    document.getElementById('confirmation_envoi_mail_fail').style.display = 'block';
                }
            }
        });
    }
}

// FIN FORMULAIRE AJAX : Contact